<template>
	<div class="user-donate">
		<w-navTab titleText="捐赠记录"></w-navTab>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="nav"><w-title title="捐赠记录"></w-title></div>
			<div class="donate-list" v-for="item in donateList">
				<div class="donate-title">{{ item.title }}</div>
				<div class="donate-bottom">
					<div class="donate-bottom-left">
						<div class="left-picture"><img src="@/assets/img/test246.png" alt="" /></div>
						<div class="left-time">{{ item.create_date }}</div>
					</div>
					<div class="donate-bottom-right">捐赠{{ item.integral }}积分</div>
				</div>
			</div>
		</VantList>
	</div>
</template>

<script>
import UserApi from '@/api/user';
import VantList from '@/components/VantList';
import WTitle from '@/components/Title';
import VantVendor from '@/vendor/vant';

export default {
	name: 'UserDonate',
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			donateList: [],
			paramsPage: 1,
			paramsLimit: 10
		};
	},

	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			let params = {
				page: this.paramsPage,
				limit: this.paramsLimit
			};

			UserApi.getMyDonate(params)
				.then(result => {
					this.paramsPage++;
					this.donateList = this.donateList.concat(result.data.list);

					this.loading = false;

					if (this.donateList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.donateList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		}
	},
	components: {
		VantList,
		WTitle
	}
};
</script>

<style lang="less" scoped>
.user-donate {
	.nav {
		padding-top: 10px;
		padding-left: 10px;
	}
	.list-view {
		padding: 0 10px;
	}

	.donate-list {
		padding: 15px 10px;
		background: #fff;
		border-radius: 8px;
		margin-top: 10px;

		.donate-title {
			font-size: 18px;
			line-height: 24px;
			color: #333;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			margin-bottom: 13px;
		}

		.donate-bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.donate-bottom-left {
				display: flex;
				align-items: center;

				.left-picture {
					width: 16px;
					height: 16px;
					margin-right: 2px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.left-time {
					font-size: 13px;
					line-height: 16px;
					color: #666;
				}
			}

			.donate-bottom-right {
				font-size: 13px;
				line-height: 16px;
				color: #f43e3d;
			}
		}
	}
}
</style>
